//import IndexMain2 from '@/components/IndexMain2';
import HeaderUserTipsH5 from '@/app/eflayout/HeaderCommon/HeaderUser/HeaderUserNone/HeaderUserTipsWin/HeaderUserTipsH5';
import PageLayout from '@/components/PageLayout';
import SEOlinksA from '@/components/SEOlinksA';
import { getPageList } from '@/service';
import { initH1Tage, isReadyLang, redirectTo404 } from '@/utils';
import { appendScriptContentToHead } from '@/utils/singUtil/appendScriptToHead';
import { useGlobal } from '@/utils/useGlobal';
import EcoCreditsWin from '@elem/compCommon/EcoCreditsWin';
import Product from 'lowcode/pages/product';
import { queryEditorDetailOnline } from 'lowcode/utils/service';
import * as Lowcomp from 'lowcomp/plugins/index-series/indexS';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import * as deviceDetect from 'react-device-detect';
import css from './index.module.scss';
const IndexMain2: any = dynamic(() => import('@/components/IndexMain2'), { ssr: true });
// 是否是配置化新首页 US/CA /AU/ZA/KR /EU/UK/DE/FR/IT/ES /JP
const newIndexArray: any = ['us', 'ca', 'au', 'za', 'eu', 'uk', 'de', 'fr', 'it', 'es', 'jp', 'kr', 'cn', 'sa', 'br']; //newHeaderLocales;
// const newIndexArray: any = ['us', 'ca', 'au', 'za', 'eu', 'uk', 'de', 'fr', 'it', 'es', 'jp', 'kr', 'cn', 'sa']; //newHeaderLocales;

export default function Index({ homeData, SEO, id, data, locale }: any) {
    const { isMobile, useLang } = useGlobal();
    const { t } = useLang('index');
    // en 只作为一个跳板，直接通过前端路由转到 /us，所以不影响 SEO
    if (locale === 'en') {
        return null;
    }
    const isNewIndex = newIndexArray.includes(locale as any);
    const isV2Flag = true;
    const [isIOS, setIsIOS] = useState<boolean>(false);

    useEffect(() => {
        setIsIOS(deviceDetect.isIOS);
        initH1Tage();

        locale === 'ca' &&
            setTimeout(() => {
                appendScriptContentToHead(`
                setTimeout(function () {
                    trackPageView();
                }, 40000);
                
                function trackPageView() {
                    const timeOnPage = Math.floor(performance.now() / 1000); // Calculate time on page in seconds
                    if (timeOnPage > 40 && typeof fbq === 'function') {
                        fbq('track', 'PageView40s');
                    }
                }
            `);
            }, 3000);
    }, []);
    const showNewTipsA = ['us', 'za', 'jp', 'ca', 'uk', 'au', 'de', 'fr', 'it', 'es', 'kr'];
    return (
        <>
            <PageLayout className={`${css.indexpage} ${isMobile ? 'indexpageH5' : 'indexpagePc'} ${!isIOS ? 'isAndriod' : ''}`}>
                <SEO title={t('seo_title') || t('Ecoflow')} description={t('seo_desc')} />
                <SEOlinksA
                    list={['us', 'za', 'jp', 'cn', 'ca', 'uk', 'au', 'de', 'fr', 'it', 'es', 'cis', 'sa', 'kr', 'br']}
                    locale={String(locale)}
                    pathname={''}
                />
                {isNewIndex && (
                    <>
                        {showNewTipsA.includes(locale) && <HeaderUserTipsH5 />}
                        {/* <IndexLoginTips /> */}
                        <Product urlId={id} data={data} allTemp={Lowcomp} />
                    </>
                )}
                {!isNewIndex && homeData && <IndexMain2 homeData={homeData} />}
            </PageLayout>
            {isNewIndex && !['sa', 'br'].includes(locale) && <EcoCreditsWin />}
        </>
    );
}

export async function getStaticProps(context: any) {
    const id: any = 'index';
    const locale: any = context.locale;
    if (locale !== 'en') {
        if (!isReadyLang(locale)) {
            return redirectTo404();
        }
    }
    let data = {
        thePage: {},
        theTemp: [],
        theData: {},
        theAttr: {},
        sqlData: {},
    };
    let homeData = null;
    // v2
    const isV2Flag = true;
    if (isV2Flag) {
        try {
            if (newIndexArray.includes(locale)) {
                //const cookieStr: any = context.req.headers.cookie;
                //const pageKey = getCookieFromVal(cookieStr, id);
                data = await queryEditorDetailOnline(id, locale);
            } else {
                const res = await getPageList(locale);
                if (res.code === '0' && res.data?.length > 0) {
                    homeData = res.data.find((el: { pageKey: string }) => el.pageKey === 'homepage');
                }
            }
        } catch (e) {
            console.log('e', e);
        }
    }
    return {
        props: {
            homeData: isV2Flag ? homeData || null : homeData || null,
            id,
            data,
            locale,
        },
        revalidate: 300,
    };
}
