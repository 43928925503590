import HeaderUserTipsContent from './ContentA';

// 没有登录
export default function HeaderUserTipsWin({ closeCallback, className }: { closeCallback?: any; className?: string }) {
    return (
        <div className="HeaderV2-user-win-h5">
            <HeaderUserTipsContent closeCallback={closeCallback} />
        </div>
    );
}
